<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('logs')"
	                    :isColumns="true"
	                    @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('logs')"
		                      :isColumns="true"
		                      @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
                              :exportExcel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('model')">
                            <model-selectbox size="sm" v-model="datatable.queryParams.filter.model"></model-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('process')">
                            <multi-selectbox :multiple="false" size="sm" v-model="datatable.queryParams.filter.process" :options="processItems"></multi-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="datatable.queryParams.filter.start_date"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="datetime-local" size="sm" v-model="datatable.queryParams.filter.end_date"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('user_id')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.user_id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('data_filter')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.datas"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('filter_filter')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.filters"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService"></datatable>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import LogService from "@/services/LogService";
    import qs from 'qs'
    import ModelSelectbox from "@/components/interactive-fields/ModelSelectbox.vue";

    export default {
        components: {
            ModelSelectbox,
            AppLayout,
            Header,
	        HeaderMobile,
            DatatableFilter,
            Datatable,
        },
        metaInfo() {
            return {
                title: this.$t('logs')
            }
        },
        data() {
            return {
                processItems: [
                    {
                        value:'insert',
                        text: 'Ekleme'
                    },
                    {
                        value:'delete',
                        text: 'Silme'
                    },
                    {
                        value:'update',
                        text: 'Güncelleme'
                    }
                ],
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: this.$t('id'),
                            field: '_id',
                            hidden: true,
                        },
                        {
                            label: this.$t('controller_name'),
                            field: 'controller_name',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('user_id'),
                            field: 'user_id',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('name_surname'),
                            field: 'person',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('model'),
                            field: 'model',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('process'),
                            field: 'process',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('data'),
                            field: 'datas',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('filters'),
                            field: 'filters',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('result'),
                            field: 'result',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('ip'),
                            field: 'ip',
                            hidden: false,
                            thClass: 'text-center',
                        },
                        {
                            label: this.$t('created_at'),
                            field: 'created_at',
                            hidden: false,
                            thClass: 'text-center',
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {
                            id: null,
                            name: null,
                        },
                        sort: 'name',
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        methods: {
            callService(config) {
                return LogService.getAll(config)
            },
            getRows() {
                this.$refs.datatable.getRows();
            }
        }
    };
</script>

